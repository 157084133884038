import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'

import BBUISidenavApps from './bbui-sidenav-apps'
import BBUISidenavItem from './bbui-sidenav-item'
import React from 'react'

interface Props {
    activeApp?: string
}

const BBUISidenavFull: React.FC<Props> = ({ activeApp }) => {
    return (
        <BBUISidenavApps>
            <BBUISidenavItem
                addMarginTop
                isActive={activeApp === 'bigbearapps'}
                href="/"
                image="https://i.imgur.com/9USGxQw.png"
                tooltipText="BigBearApps"></BBUISidenavItem>
            <BBUISidenavItem
                addMarginTop
                isActive={activeApp === 'bigbearaccount'}
                href="/bigbearaccount"
                avatar="https://i.imgur.com/XOnGcSq.png"
                tooltipText="BigBearAccount"></BBUISidenavItem>
            <BBUISidenavItem
                addMarginTop
                isActive={activeApp === 'boxinvy'}
                href="/boxinvy"
                image="https://images.bigbeartechworld.workers.dev/?width=96&quality=75&image=https://bigbearfrontend.pages.dev/_next/static/media/boxinvy.ba81582a.png"
                tooltipText="BoxInvy"></BBUISidenavItem>
            <BBUISidenavItem
                addMarginTop
                isActive={activeApp === 'bigbearhealth'}
                href="/bigbearhealth"
                image="https://cdn-media.bigbeartechworld.com/logos/bigbearhealth.png"
                tooltipText="BigBearHealth"></BBUISidenavItem>
            <BBUISidenavItem
                addMarginTop
                isActive={activeApp === 'doorknockerpal'}
                href="/doorknockerpal"
                image="https://images.bigbeartechworld.workers.dev/?width=96&quality=75&image=https://bigbearfrontend.pages.dev/_next/static/media/doorknockerpal.4bf67da5.png"
                tooltipText="DoorknockerPal"></BBUISidenavItem>
            <BBUISidenavItem
                addMarginTop
                isActive={activeApp === 'emailcove'}
                href="/emailcove"
                image="https://images.bigbeartechworld.workers.dev/?width=96&quality=75&image=https://bigbearfrontend.pages.dev/_next/static/media/emailcove.f61b68b5.png"
                tooltipText="Emailcove"></BBUISidenavItem>
            <BBUISidenavItem
                addMarginTop
                isActive={activeApp === 'goalpense'}
                href="/goalpense"
                image="https://images.bigbeartechworld.workers.dev/?width=96&quality=75&image=https://bigbearfrontend.pages.dev/_next/static/media/goalpense.7bb38696.png"
                tooltipText="Goalpense"></BBUISidenavItem>
            <BBUISidenavItem
                addMarginTop
                isActive={activeApp === 'mealinvy'}
                href="/mealinvy"
                image="https://images.bigbeartechworld.workers.dev/?width=96&quality=75&image=https://bigbearfrontend.pages.dev/_next/static/media/mealinvy.fae7ee27.png"
                tooltipText="Mealinvy"></BBUISidenavItem>
            <BBUISidenavItem
                addMarginTop
                isActive={activeApp === 'panelbay'}
                href="/panelbay"
                image="https://images.bigbeartechworld.workers.dev/?width=96&quality=75&image=https://bigbearfrontend.pages.dev/_next/static/media/panelbay.75484648.png"
                tooltipText="Panelbay"></BBUISidenavItem>
            <BBUISidenavItem
                addMarginTop
                isActive={activeApp === 'reviewpal'}
                href="/reviewpal"
                image="https://images.bigbeartechworld.workers.dev/?width=128&quality=75&image=https://bigbearfrontend.pages.dev/_next/static/media/reviewpal.9b165465.png"
                tooltipText="ReviewPal"></BBUISidenavItem>
            <BBUISidenavItem
                addMarginTop
                isActive={activeApp === 'wealtor'}
                href="/wealtor"
                image="https://images.bigbeartechworld.workers.dev/?width=96&quality=75&image=https://bigbearfrontend.pages.dev/_next/static/media/wealtor.0872704e.png"
                tooltipText="Wealtor"></BBUISidenavItem>
        </BBUISidenavApps>
    )
}

export default BBUISidenavFull
