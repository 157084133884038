import { BBUIAxiosQuery, DoorKnockerPalPaths } from '@bigbearui/index'
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'

export const doorknockerpalTeamKeys = {
    all: ['teams'] as const,
    lists: () => [...doorknockerpalTeamKeys.all, 'list'] as const,
    list: (filters: object) =>
        [...doorknockerpalTeamKeys.lists(), filters] as const,
    details: () => [...doorknockerpalTeamKeys.all, 'detail'] as const,
    setupTeam: () => [...doorknockerpalTeamKeys.all, 'setup-team'] as const,
    detail: (id: number | string) =>
        [...doorknockerpalTeamKeys.details(), id] as const,
}

export const getTeamsReactQuery = ({
    searchType,
    orderByField,
    orderByValue,
    match,
    paginatorInfo,
}) =>
    useInfiniteQuery<
        DoorKnockerPalPaths['/doorknockerpal/api/v1/teams']['get']['responses']['200']['application/json']['data']['data'],
        Error
    >(
        doorknockerpalTeamKeys.list({
            searchType,
            orderByField,
            orderByValue,
            match,
        }),
        async ({ pageParam = 0 }) => {
            const response: DoorKnockerPalPaths['/doorknockerpal/api/v1/teams']['get']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: '/doorknockerpal/api/v1/teams',
                    params: {
                        page: pageParam,
                        count: 20,
                        search: searchType,
                        order_by_field: orderByField,
                        order_by_value: orderByValue,
                        match,
                    },
                    method: 'GET',
                },
            )
            paginatorInfo = response.data.data.meta
            return response.data.data
        },
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage.meta.last_page === lastPage.meta.current_page) {
                    return undefined
                }
                return lastPage.meta.current_page + 1
            },
        },
    )

export const currentTeamReactQuery = () =>
    useQuery<
        DoorKnockerPalPaths['/doorknockerpal/api/v1/current-team']['get']['responses']['200']['application/json']['data']['data'],
        Error
    >(doorknockerpalTeamKeys.detail('current-team'), async () => {
        const response: DoorKnockerPalPaths['/doorknockerpal/api/v1/current-team']['get']['responses']['200']['application/json'] = await BBUIAxiosQuery(
            {
                url: `/doorknockerpal/api/v1/current-team`,
                params: {},
                method: 'GET',
            },
        )
        return response.data.data
    })

export const setupTeamReactQuery = () =>
    useQuery<
        DoorKnockerPalPaths['/doorknockerpal/api/v1/setup-team']['get']['responses']['200']['application/json']['data']['data'],
        Error
    >(
        doorknockerpalTeamKeys.setupTeam(),
        async () => {
            const response: DoorKnockerPalPaths['/doorknockerpal/api/v1/setup-team']['get']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: `/doorknockerpal/api/v1/setup-team`,
                    params: {},
                    method: 'GET',
                },
            )
            return response.data.data
        },
        { retry: 2 },
    )

export const updateTeamMutation = ({}) =>
    useMutation(
        async ({
            name,
            mode_id,
            timezone,
        }: DoorKnockerPalPaths['/doorknockerpal/api/v1/team']['put']['requestBody']['application/json']) => {
            const data: DoorKnockerPalPaths['/doorknockerpal/api/v1/team']['put']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: `/doorknockerpal/api/v1/team`,
                    data: {
                        name,
                        mode_id,
                        timezone,
                    },
                    method: 'PUT',
                },
            )
            return data.data
        },
    )

export const switchTeamMutationReactQuery = ({ subTeamId }) =>
    useMutation(
        async ({}: DoorKnockerPalPaths['/doorknockerpal/api/v1/teams/switch']['post']['requestBody']['application/json']) => {
            const data: DoorKnockerPalPaths['/doorknockerpal/api/v1/teams/switch']['post']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: `/doorknockerpal/api/v1/teams/switch`,
                    data: { team_id: subTeamId },
                    method: 'POST',
                },
            )
            return data.data
        },
    )

export const switchParentTeamMutationReactQuery = ({}) =>
    useMutation(
        async ({
            team_id,
        }: DoorKnockerPalPaths['/doorknockerpal/api/v1/teams/switch']['post']['requestBody']['application/json']) => {
            const data: DoorKnockerPalPaths['/doorknockerpal/api/v1/teams/switch']['post']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: `/doorknockerpal/api/v1/teams/switch`,
                    data: { team_id },
                    method: 'POST',
                },
            )
            return data.data
        },
    )

export const createTeamMutationReactQuery = ({}) =>
    useMutation(
        async ({
            name,
        }: DoorKnockerPalPaths['/doorknockerpal/api/v1/teams']['post']['requestBody']['application/json']) => {
            const data: DoorKnockerPalPaths['/doorknockerpal/api/v1/teams']['post']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: `/doorknockerpal/api/v1/teams`,
                    data: { name },
                    method: 'POST',
                },
            )
            return data.data
        },
    )

export const getCurrentTeamReactQuery = () =>
    useQuery<
        DoorKnockerPalPaths['/doorknockerpal/api/v1/current-team']['get']['responses']['200']['application/json']['data']['data'],
        Error
    >(
        doorknockerpalTeamKeys.detail('current-team'),
        async (key, cursor = 0) => {
            const response: DoorKnockerPalPaths['/doorknockerpal/api/v1/current-team']['get']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: `/doorknockerpal/api/v1/current-team`,
                    params: {},
                    method: 'GET',
                },
            )
            return response.data.data
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    )
