import { ActivityIndicator, StatusBar, View } from 'react-native'

import { BBUIPage } from '@bigbearui/index'
import React from 'react'
import { setupTeamReactQuery } from '../team-queries'
import { styled } from 'dripsy'

const StyledContainer = styled(View)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
})

const CheckForTeam = ({ children, isVisible = true }) => {
    if (!isVisible) return children

    const setupTeam = setupTeamReactQuery()

    if (setupTeam.isLoading) {
        return (
            <BBUIPage testID="scrollView" isScrollable={false}>
                {({}) => {
                    return (
                        <>
                            <StatusBar barStyle="light-content" />
                            <StyledContainer>
                                <ActivityIndicator size="large" color="#fff" />
                            </StyledContainer>
                        </>
                    )
                }}
            </BBUIPage>
        )
    }

    if (setupTeam.isError) {
        if (
            // @ts-ignore
            setupTeam?.error?.response?.data.message.includes('Unauthenticated')
        ) {
            window.location.href = `/accounts/login?redirect=${encodeURIComponent(
                window.location.href,
            )}`
        } else {
            window.location.href = `/accounts/login?redirect=${encodeURIComponent(
                window.location.href,
            )}`
        }
        return null
    }

    return children
}

export default CheckForTeam
