import React from 'react'
import ReactModal from 'react-modal'
import { useBBUINiceModalStore } from '@bigbearui/index'

ReactModal.setAppElement('#app')

const ModalRegister = React.memo(
    ({ modals }: { modals: any }) => {
        return (
            modals.map((modal, index) => {
                return (
                    <modal.component
                        key={modal.componentId}
                        componentId={modal.componentId}
                        {...modal.props}
                    />
                )
            }) || null
        )
    },
    (prevProps, nextProps) => {
        if (prevProps.modals.length !== nextProps.modals.length) {
            return false
        }
        return true
    },
)

const Modals = ({}) => {
    const { modals } = useBBUINiceModalStore()

    return (
        <>
            <ModalRegister modals={modals} />
        </>
    )
}

export default Modals
