import { AppShell, Navbar } from '@mantine/core'

import { BBUISidenavFull } from '../../lib/webui'
import { DripsyProvider } from 'dripsy'
import Head from 'next/head'
import Modals from '../modals'
import React from 'react'
import { doorknockerpalTheme } from '@bigbearui/index'
import { useAuth } from '../../hooks/auth'

const DoorKnockerPalLayout = ({ children }) => {
    const { user } = useAuth({ middleware: 'auth' })

    return (
        <>
            <Head>
                <link
                    rel="shortcut icon"
                    href="/favicons/doorknockerpal/favicon.ico?v=yyajQO0Rqm"
                />
                <meta
                    name="apple-mobile-web-app-title"
                    content="DoorKnockerPal"
                />
                <meta name="application-name" content="DoorKnockerPal" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#5A1E2F" />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/favicons/doorknockerpal/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicons/doorknockerpal/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicons/doorknockerpal/favicon-16x16.png"
                />
                <link
                    rel="manifest"
                    href="/favicons/doorknockerpal/site.webmanifest"
                />
            </Head>
            <DripsyProvider
                ssr
                ssrPlaceholder={null} // optional
                theme={doorknockerpalTheme}>
                <AppShell
                    padding="none"
                    fixed={true}
                    navbar={
                        <Navbar
                            width={{
                                65: 65,
                                xs: 65,
                                sm: 65,
                                lg: 65,
                            }}>
                            <BBUISidenavFull activeApp="doorknockerpal" />
                        </Navbar>
                    }>
                    <div
                        style={{
                            marginLeft: 65,
                        }}
                        id="doorknockerpal-layout">
                        {children}
                    </div>
                </AppShell>
                <Modals />
            </DripsyProvider>
        </>
    )
}

export default DoorKnockerPalLayout
