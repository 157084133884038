import React from 'react'

interface Props {
    children?: React.ReactNode
}

const BBUISidenavApps: React.FC<Props> = ({ children }) => {
    return (
        <aside
            className="z-40 w-16 px-2 h-screen bg-white border-r border-gray-200 "
            aria-label="SidenavApps"
            id="drawer-navigation">
            <ul>{children}</ul>
        </aside>
    )
}

export default BBUISidenavApps
