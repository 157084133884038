import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'

import Icon from 'react-native-vector-icons/FontAwesome5'
import Link from 'next/link'
import React from 'react'
import { Tooltip } from '@mantine/core'

interface Props {
    avatar?: string
    image?: string
    icon?: string
    href?: string
    isActive?: boolean
    addMarginTop?: boolean
    tooltipText?: string
}

const BBUISidenavItem = ({
    avatar = '',
    image = '',
    icon = '',
    href = '#',
    isActive = false,
    addMarginTop = false,
    tooltipText = '',
}) => {
    if (avatar) {
        return (
            <Tooltip label={tooltipText} position="right">
                <li className={addMarginTop ? 'mt-2' : ''}>
                    <div
                        className={`p-1 hover:shadow-sm hover:bg-gray-100 hover:rounded-md text-center ${
                            isActive
                                ? 'bg-gray-300 border-r-2 border-gray-500 rounded-md'
                                : ''
                        }`}>
                        <Link href={href}>
                            <Avatar>
                                <AvatarImage src={avatar} />
                                <AvatarFallback>A</AvatarFallback>
                            </Avatar>
                        </Link>
                    </div>
                </li>
            </Tooltip>
        )
    }
    if (icon) {
        return (
            <Tooltip label={tooltipText} position="right">
                <li className={addMarginTop ? 'mt-2' : ''}>
                    <div
                        className={`p-2 hover:shadow-sm hover:bg-gray-100 hover:rounded-md text-center ${
                            isActive
                                ? 'bg-gray-300 border-r-2 border-gray-500 rounded-md'
                                : ''
                        }`}>
                        <Link href={href}>
                            <Icon name={icon} size={27} color="black" />
                        </Link>
                    </div>
                </li>
            </Tooltip>
        )
    }

    return (
        <Tooltip label={tooltipText} position="right">
            <li className={addMarginTop ? 'mt-2' : ''}>
                <div
                    className={`p-1 hover:shadow-sm hover:bg-gray-100 hover:rounded-md text-center ${
                        isActive
                            ? 'bg-gray-300 border-r-2 border-gray-500 rounded-md'
                            : ''
                    }`}>
                    <Link href={href}>
                        <Avatar className="rounded-md">
                            <AvatarImage src={image} />
                            <AvatarFallback>A</AvatarFallback>
                        </Avatar>
                    </Link>
                </div>
            </li>
        </Tooltip>
    )
}

export default BBUISidenavItem
