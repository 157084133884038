import React from 'react'

interface Props {
    componentId: string
}

const BBUITopBar: React.FC<Props> = ({ componentId }) => {
    return (
        <>
            {/* <BBUIUpdateTopbar componentId={componentId} />
      <BBUINetworkTopbar componentId={componentId} />
      <BBUIUploadingTopbar componentId={componentId} /> */}
            {/* <BoxinvyFetchingIndicator componentId={componentId} /> */}
        </>
    )
}

export default BBUITopBar
