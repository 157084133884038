import axios from '../lib/axios'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import useSWR from 'swr'

interface Props {
    middleware?: 'guest' | 'auth' | 'none'
    redirectIfAuthenticated?: string
    authType?: string | 'wealtor-agent-site'
    redirect?: string
    id?: string | string[]
}

export const useAuth = ({
    middleware = 'none',
    redirectIfAuthenticated,
    authType,
    redirect,
    id,
}: Props) => {
    const router = useRouter()

    const { data: user, error, mutate } = useSWR(
        `${process.env.NEXT_PUBLIC_APP_BASE_URL}/bigbearaccounts/api/v1/me?type=${authType}&redirect=${redirect}&id=${id}`,
        () =>
            axios()
                .get(
                    `${process.env.NEXT_PUBLIC_APP_BASE_URL}/bigbearaccounts/api/v1/me?type=${authType}&redirect=${redirect}&id=${id}`,
                )
                .then(res => res.data)
                .catch(error => {
                    if (middleware === 'auth') {
                        if (error.response.status !== 409) throw error

                        router.push('/verify-email')
                    }
                }),
    )

    const csrf = () => axios().get('/sanctum/csrf-cookie')

    const register = async ({ setErrors, ...props }) => {
        await csrf()

        setErrors([])

        axios()
            .post('/register', props)
            .then(() => mutate())
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const login = async ({ setErrors, setStatus, ...props }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios()
            .post('/login', props)
            .then(() => mutate())
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const forgotPassword = async ({ setErrors, setStatus, email }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios()
            .post('/forgot-password', { email })
            .then(response => setStatus(response.data.status))
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resetPassword = async ({ setErrors, setStatus, ...props }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios()
            .post('/reset-password', { token: router.query.token, ...props })
            .then(response =>
                router.push('/login?reset=' + btoa(response.data.status)),
            )
            .catch(error => {
                if (error.response.status != 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resendEmailVerification = ({ setStatus }) => {
        axios()
            .post('/email/verification-notification')
            .then(response => setStatus(response.data.status))
    }

    const logout = async () => {
        console.log('logout', middleware)
        if (!error) {
            await axios()
                .post('/logout')
                .then(() => mutate())
        }

        window.location.href = `/accounts/login?redirect=${encodeURIComponent(
            window.location.href,
        )}`
    }

    useEffect(() => {
        if (middleware === 'guest' && redirectIfAuthenticated && user) {
            if (authType === 'wealtor-agent-site') {
                if (user.data.item.meta.magic_link) {
                    window.location.href = user.data.item.meta.magic_link
                } else {
                    router.push(redirectIfAuthenticated)
                }
                return
            }
            if (redirectIfAuthenticated === 'undefined') {
                router.push('/')
                return
            }
            router.push(redirectIfAuthenticated)
        }
        if (middleware === 'auth' && error) logout()
    }, [user, error])

    return {
        user,
        register,
        login,
        forgotPassword,
        resetPassword,
        resendEmailVerification,
        logout,
    }
}
