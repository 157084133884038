import Axios from 'axios'
import Cookies from 'js-cookie'

const axios = () => {
    const value = Cookies.get('BigBearAccount')
    return Axios.create({
        baseURL: process.env.NEXT_PUBLIC_APP_BASE_URL,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            authorization: value ? `Bearer ${value}` : '',
        },
        withCredentials: true,
    })
}

export default axios
